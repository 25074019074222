import React, { useState } from 'react';
import styled from '@emotion/styled';

const StyledViewmore = styled.div`
  a {
    color: #2d53da !important;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer; /* Add cursor pointer for better UX */
  }
`;

function ViewMore({ value, count = 50 }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {value == null || value === '' ? '-' : (
        <>
          {value.length > count ? (
            <>
              {isExpanded ? (
                <StyledViewmore>
                  {value}{' '}
                  <a
                    onClick={(event) => {
                      event.stopPropagation(); // Prevents click event from bubbling up
                      setIsExpanded(false);
                    }}
                  >
                    View less
                  </a>
                </StyledViewmore>
              ) : (
                <StyledViewmore>
                  {value.substring(0, count)}...{' '}
                  <a
                    onClick={(event) => {
                        event.stopPropagation(); // Prevents click event from bubbling up
                      setIsExpanded(true);
                    }}
                  >
                    View more
                  </a>
                </StyledViewmore>
              )}
            </>
          ) : (
            <>{value}</>
          )}
        </>
      )}
    </>
  );
}

export default ViewMore;
