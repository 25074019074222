
import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Form,
    Input,
    DatePicker,
    Select,
    message,
    Checkbox,
    Switch,
    Upload,
    Space,
    TimePicker,
    Button
} from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import {
    NON_EMPTY_REGEX,
    EMAIL_REGEX,
    countryList,
    Greetings,
    AwardList,
} from "common/Constants";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isEmpty } from 'lodash';
import { CALL_API } from 'common/API';


const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  margin: 10px;
`;

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-picker{
    width: 100% !important;
  }
  .ant-btn.ant-btn-default.ant-btn-lg.w-100.text-start{
    justify-content: flex-start;
  }
  .attach_file{
    background: #c2fae5 !important;
    span{
      color:#1b1b1bb3 !important;
    }
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;

function WorkInFormation({setStep, goBack, setFormValues, formValues, form}) {
    const { Option } = Select;
    const containerRef = useRef(null);
    const onFinish = (values) => {
        const currentFormValues = form.getFieldValue();
        setFormValues((prevValues) => ({
            ...prevValues,
            ...currentFormValues,
          }));
        setStep(3)
        console.log('Received values:', values);
        // Handle API calls or state updates here
    };

    // useEffect(()=> {
    //   if(!isEmpty(formValues)){
    //     form.setFieldsValue(formValues);
    //   }
    // }, [formValues])
    const uploadFile = async (value) => {
      const { file } = value;
      try {
        if (!isEmpty(file)) {
          const { user_id } = formValues;
          await CALL_API(`upload-userdocuments/BDIX4EW`, "post", {
            userId: user_id?.toLowerCase(),
            name: file.name,
            type: file.type,
            uniqueId: file.uniqueId || file.uid,
            content: file.base64Data|| "",
          });
        }
      } catch (error) {
        console.error("[handlesubmit] error --->", error);
      }
    };

    function generateUniqueId() {
      const usedIds = [];
      let uniqueId = "";
      while (uniqueId.length !== 6) {
        const randomNumber = Math.floor(Math.random() * 900000) + 100000;
        if (!usedIds.includes(randomNumber.toString())) {
          usedIds.push(randomNumber.toString());
          uniqueId += randomNumber.toString();
        }
      }
      return uniqueId;
    }
    return (
        <div>
               <>
              <b>Work Experience:</b>
              <Form.List name="workexperience">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Company name"
                            name={[name, "companyname"]}
                            rules={[
                              {
                                required: true,
                                message: "Company name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Company name"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Job Title"
                            name={[name, "jobtitle"]}
                            rules={[
                              {
                                required: true,
                                message: "Job Title required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Job Title"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            // name= "fromdate"
                            rules={[
                              { required: true, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: true, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Relevant"
                            name={[name, "relevant"]}
                            rules={[
                              { required: true, message: "Relevant required" },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              // onChange={onFinish}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                        {/* <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Job Description"
                            name={[name, "jobdescription"]}
                            rules={[
                              {
                                required: true,
                                message: "Job Description required",
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder="Job Description"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={24} md={24}>
                        {/* <Form.Item
                          label="Experience Letter"
                          name="experienceletter"
                          rules={[
                            
                            {
                              required: true,
                              message: "Experience Letter is required",
                            }
                          ]}
                        >
                          <Upload
                            listType="text"
                            onRemove={() => {
                              form.resetFields(['experienceletter']);
                            }}
                            beforeUpload={() => false}
                            maxCount={1}
                            className='cv_file'
                            onChange={(value) => {
                              //  onFinish();
                              uploadFile(value);
                            }}
                          >
                          
                          <div style={{ width: "100%" }}>
                                            <Button
                                              className="w-100 text-start"
                                              size="large"
                                            >
                                              Upload File
                                            </Button>
                                          </div>
                          </Upload>
                        </Form.Item> */}

                        <Form.Item
                            {...restField}
                            label="Attachment"
                            name={[name, "attachment"]}
                            valuePropName="fileList"
                            rules={[
                              {
                                required: true,
                                message: "Attachment required",
                              },
                            ]}
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                          >
                            <Upload
                              customRequest={({ file, onSuccess }) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);

                                reader.onload = () => {
                                  const base64Data = reader.result.split(",")[1];
                                  const uniqueId = generateUniqueId();
                                  file.base64Data = base64Data; // Add base64Data to file object
                                  file.uniqueId = uniqueId; // Add uniqueId to file object
                                  onSuccess({ file });
                                };

                                reader.onerror = (error) => {
                                  console.error("File read error:", error);
                                };
                              }}
                              name="logo"
                              // action="/upload.do"
                              onChange={(value) => {
                                //  onFinish();
                                uploadFile(value);
                              }}
                            
                              listType="text"
                              style={{ width: "100%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  className="w-100 text-start attach_file"
                                  size="large"
                                  style={{fontWeight:"600"}}
                                >
                                  Upload File
                                </Button>
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </StyleRow>
                    ))}
                    <Form.Item className='ms-0'>
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        style={{cursor: "pointer", marginLeft:"0px"}}
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              
            </>
        </div>
    )
}

export default WorkInFormation