import React, { useState } from "react";
import { Form, Input, Row, Col, message, Button, Modal } from "antd";
// Theme
import styled from "@emotion/styled";
// Common
import FONTS from "common/Fonts";
import COLORS from "common/Colors";
import { LOGIN_BASE_URL, NON_EMPTY_REGEX, SERVICE_URL } from "common/Constants";
import axios from "axios";
import logo from "../../assets/BDO.svg";
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';

const DivContainer = styled.div`
  height: 100vh;
  padding-top: 15%;
  padding: 10px 10px 10px 10px;
  background-image: url("login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-height: 625px) {
    justify-content: flex-start;
  }
`;
const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  .logo {
    width: 100%;
    max-width: 175px;
    margin: 0 auto;
    padding: 40px 30px 30px 40px;
    border-radius: 26px;
  }

  /* height very lower then position should be relative */
  @media (max-height: 625px) {
    position: relative;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
  border-radius: 26px;
  box-shadow: 0px 3px 6px #00000029;
`;

const StyledCenterAlign = styled.h3`
  text-align: left;
  font-size: 1.4rem;
  padding-bottom: 10px;
  padding-top: 30px;
  font-weight: ${FONTS.WEIGHT.HEAVY};
`;

const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: ${COLORS.TEXT.WHITE};
  }

  .ant-form-item {
    margin-bottom: 1.5rem !important;
    .ant-form-item-explain-error{
      text-align: left;
    }
    .ant-form-item-control-input-content {
      input {
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        height: 60px;
        padding-left: 20px;
        color: ${COLORS.TEXT.PRIMARY};
        font-size: 1rem;
        font-weight: ${FONTS.WEIGHT.MEDIUM};
        &:focus {
          border-color: ${COLORS.PRIMARY};
        }
      }
      .ant-input-affix-wrapper.ant-input-password {
        border-radius: 12px;
        padding: 0px;
        input {
          border: none;
        }
      }

      .ant-input-suffix {
        padding: 0px 8px 0px 8px;
        margin: 0px;
      }
    }
  }
`;

const StyleSubmitButton = styled(Button)`
  font-weight: bold;
  height: 60px;
  width: 90%;
  border-radius: 12px;
  color: #000000;
`;
const StyleSubmitButton1 = styled(Button)`
  font-weight: bold;
  height: 40px;
  width: 45%;
  border-radius: 12px;
  color: #000000;
`;

const StyledErrorDiv = styled.div`
  color: #ff4d4d;
  font-weight: bold;
  /* text-shadow: 0.5px 0.5px black; */
  font-size: 0.8rem;
  padding-top: 10px;
`;

const StyledSuccessModal = styled(Modal)`
.ant-modal-body {
  font-weight: bold;
  padding: 20px 10px;
  padding-left: 40px;
  .loading-message {
    padding-left: 50px;
  }
}
.ant-modal-footer {
  border-top: none;
  padding: 0px;
  margin: 0px;
}
.anticon-check-circle {
  font-size: 4rem;
  display: block;
  padding: 10px;
  color: #2CFF00;
}
`;
const StyledSuccessModal1 = styled(Modal)`
.success_p{
  font-size: 1rem;  
  text-align: center;
}
.ant-modal-body {
  font-weight: bold;
  padding: 20px 10px;
  
}
.ant-modal-footer {
  border-top: none;
  padding: 0px;
  margin: 0px;
}
.anticon-check-circle {
  font-size: 4rem;
  display: block;
  padding: 10px;
  color: #2CFF00;
}
`;

const SignIn = ({ setLogged, isSubmitted, setIsSubmitted }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      setErrorMessage("");
      const payload = {
        email: values?.username?.toLowerCase(),
        password: values?.password,
        method: "individualuserform",
      };
      const response = await axios.post(`${SERVICE_URL}user/login`, payload);
      const token = response?.data?.authorizationToken;
      if (response?.data?.status) {
        window.localStorage.setItem("token", token);
        sessionStorage.setItem(
          "tokenTimestamp",
          new Date().getTime().toString()
        );
        setLoading(false);
        setLogged(true);
      } else {
        message.error(response?.data?.message);
        setLoading(false);

      }
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      console.error("Error fetching data:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("[onFinishFailed] Failed -->", errorInfo);
  };

  return (
    <>
      <DivContainer>
          <LogoContainer>
            <img className="logo" src={logo} alt="logo" />
          </LogoContainer>

          <StyledContainer>
            <div>
              <StyledCenterAlign className="text-center">
                Login to your account
              </StyledCenterAlign>
              <StyledForm
                layout="vertical"
                className="text-center mb-5"
                name="basic"
                initialValues={{
                  username:
                    navigator.userAgent === "application"
                      ? window.localStorage.getItem("username") || ""
                      : "",
                  password:
                    navigator.userAgent === "application"
                      ? window.localStorage.getItem("password") || ""
                      : "",
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Username is required",
                    },
                  ]}
                >
                  <Input placeholder="User Name" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Password is required",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <StyleSubmitButton type="primary" htmlType="submit">
                  Log In
                </StyleSubmitButton>            

                {errorMessage && (
                  <StyledErrorDiv className="align-center">
                    {errorMessage}
                  </StyledErrorDiv>
                )}
              </StyledForm>
            </div>
          </StyledContainer>
        </DivContainer>
         <StyledSuccessModal
            title=""
            visible={loading}
            centered
            closable={false}
            keyboard={false}
            maskClosable={false}
            width={350}
            footer={[]}
            onCancel={() => setLoading(false)}
          >
            <Spin indicator={
              <LoadingOutlined style={{ fontSize: 30 }} spin />
            } />
            <span className='loading-message'>Please wait...</span>
          </StyledSuccessModal>
          <StyledSuccessModal1
            title=""
            visible={isSubmitted}
            centered
            closable={false}
            keyboard={true}
            maskClosable={false}
            // width={350}
            footer={[]}
            onCancel={() => setIsSubmitted(false)}
          >
            <p className="success_p pb-2 px-2">Thank you for your valuable time in sharing the data. Please keep in touch with the BDO HR team if you have any concerns or queries.</p>
            <div className="text-center">
              <StyleSubmitButton1 type="primary" htmlType="button" onClick={()=>setIsSubmitted(false)}>
                Close
              </StyleSubmitButton1>
            </div> 
          
          </StyledSuccessModal1>
     
    </>
  );
};

export default SignIn;
