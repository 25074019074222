import logo from './logo.svg';
import './App.css';
import OnboardUser from 'pages/OnboardUser';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SignIn from 'pages/SignIn';
import AddUser from 'pages/AddUser';

function App() {
  const [isSubmitted, setIsSubmitted] = useState(false);
 

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <AddUser isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted}/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
